import service from '@/utils/request'
import axios from 'axios'
import Compressor from "compressorjs";
//修改上传信息接口
// export function editBasicApi(params) {
//   return service({
//     url: `/jianchadan/photo`,
//     method: 'post',
//     data: params
//   })
// }
// 验证登录接口

export async function loginRequest(username, password) {
  console.log("888")
  return axios.post('/api/auth/userLogin', {
    username: username,
    password: password
  })
}
// 获取信息

export function getlistdata() {

  var profile = JSON.parse(localStorage.getItem('profile'));
  console.log('111', profile.access_token)
  return service({
    url: '/snapshot',
    method: 'get',
    headers: {
      Authorization: `Bearer ${profile.access_token}`
    }
  })
}
//巡检上传信息
export function posttroubledata(data) {
  var profile = JSON.parse(localStorage.getItem('profile'));
  console.log('a', profile.access_token)
  console.log({ type: 'inspections', ...data })
  return axios
    .post(
      '/api/snapshot',
      { type: 'inspections', ...data },
      {
        headers: {
          Authorization: `Bearer ${profile.access_token}`
        }
      }
    )
    .catch(error => {
      console.error(error)
    })
}
//安全教育上传信息
export function postsafetydata(data) {
  var profile = JSON.parse(localStorage.getItem('profile'));
  return axios
    .post(
      '/api/snapshot',
      { type: 'safety', ...data },
      {
        headers: {
          Authorization: `Bearer ${profile.access_token}`
        }
      }
    )
    .catch(error => {
      console.error(error)
    })
}

//质量检查上传信息
export function postqualitydata(data) {
  var profile = JSON.parse(localStorage.getItem('profile'));
  return axios
    .post(
      '/api/snapshot',
      { type: 'quality', ...data },
      {
        headers: {
          Authorization: `Bearer ${profile.access_token}`
        }
      }
    )
    .catch(error => {
      console.error(error)
    })
}
//隐患检查上传信息
export function postdangerdata(data) {
  var profile = JSON.parse(localStorage.getItem('profile'));
  return axios
    .post(
      '/api/snapshot',
      { type: 'troubleshooting', ...data },
      {
        headers: {
          Authorization: `Bearer ${profile.access_token}`
        }
      }
    )
    .catch(error => {
      console.error(error)
    })
}
//获取/api/auth/profile
export function getprofile() {
  var profile = JSON.parse(localStorage.getItem('profile'));
  return service({
    url: `/auth/profile`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${profile.access_token}`
    }
  })
}
/**
 * 获取图库中图片的blob对象
 * @param {string} fileName 图片名称
 * @returns
 */
export const getImageBuffer = async (fileName) => {
  var profile = JSON.parse(localStorage.getItem('profile'));
  const res = await axios.get("api/photo", {
    params: { fileName: fileName },
    headers: {
      Authorization: `Bearer ${profile.access_token}`,
    },
  });
  const imgBlob = new Blob([
    new Uint8Array(res.data.data.data, 0, res.data.data.data.length),
  ]);
  const url = URL.createObjectURL(imgBlob);
  console.log('d', url)
  return url;
};
//上传解决问题情况
export function posthandledata(idd, data) {
  var profile = JSON.parse(localStorage.getItem('profile'));
  console.log('nihao', { id: idd, handleDetail: data })
  return axios
    .post(
      '/api/snapshot/handle',
      { id: idd, handleDetail: data },
      {
        headers: {
          Authorization: `Bearer ${profile.access_token}`
        }
      }
    )
    .catch(error => {
      console.error(error)
    })
}

//上传图片
// var profile = JSON.parse(localStorage.getItem('profile'));
// const token = profile.access_token;
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJ0ZXN0Iiwicm9sZSI6IjMiLCJwcm9qZWN0SWQiOlsiMSIsIjIiXSwiYWRkVGltZSI6IjIwMjMtMDktMDMgMDE6NDI6MjUiLCJkZXNjcmlwdGlvbiI6Iumaj-aJi-aLjea1i-ivlSIsImlhdCI6MTY5Mzg4NDQ3M30.bOMK5-lra04nT5ReRwPezBOi4wXLxVacuV8hUfeYVaY";

/**
 * 上传图片
 * @param {*} file
 * @returns
 */
export const uploadFile = async (file) => {
  var profile = JSON.parse(localStorage.getItem('profile'));
const token = profile.access_token;
  console.log("upload", file);

  const url = "api/photo";

  // 压缩图片
  const compressor = new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.4,
      convertSize: 1,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        console.log(result);
        const formData = new FormData();

        // The third parameter is required for server
        formData.append("file", result, result.name);

        console.log(
          "压缩后大小为：",
          (result.size / 1024 / 1024).toFixed(1),
          "M"
        );

        // Send the compressed image file to server with XMLHttpRequest.
        resolve(
          axios.post(url, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
        );
      },
      error(err) {
        console.log(err.message);
        reject("失败");
      },
    });
  });

  const res = await compressor;

  return res;
};

// export function postImage(file) {
//   var profile = JSON.parse(localStorage.getItem('profile'));
//   const formData = new FormData()
//   formData.append('file', file, file.name)
//   return axios
//     .post('/api/photo', formData, {
//       headers: {
//         Authorization: `Bearer ${profile.access_token}`,
//         'Content-Type': 'application/x-www-form-urlencoded'
//       }
//     })
//     .then(res => {
//       return res.data
//     })
//     .catch(error => {
//       console.error(error)
//     })
// }
