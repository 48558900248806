<template>
  <div class="page flex-col">
    <img
      class="login-page-logo"
      referrerpolicy="no-referrer"
      src="../../assent/logo.png"
    />
    <img class="t_img" src="../../assent/login-top.png" />
    <span class="login_text_1" @click="ad">智慧工地随手拍</span>
    <div class="login_input_1 flex-row">
      <img
        class="icon_1"
        referrerpolicy="no-referrer"
        src="../../assent/login-yonghu.png"
      />
      <a-input
        class="login_text_4"
        :bordered="false"
        v-model:value="username"
        placeholder="请输入用户名"
        style="width: 75%"
      />
    </div>
    <div class="login_input_2 flex-row">
      <img
        class="icon_2"
        referrerpolicy="no-referrer"
        src="../../assent/login-password.png"
      />
      <a-input-password
        class="login_text_5"
        :bordered="false"
        v-model:value="password"
        placeholder="请输入密码"
        style="width: 75%"
      />
    </div>
    <a-checkbox style="margin: 5px 0 20px 20px" v-model:checked="remember"
      >记住账号密码</a-checkbox
    >
    <a-button class="login_button_1" type="primary" @click="login">
      登录
    </a-button>
    <img class="b_img" src="../../assent/login-b.png" />
  </div>
</template>
<script scoped>
import router from "../../router/index";
import { getuserdata, loginRequest } from "../../api/api";
import { message } from "ant-design-vue";
export default {
  data() {
    return {
      constants: {},
      username: "",
      password: "",
      remember: false,
      isLoggedIn: false,
    };
  },
  mounted() {
    // 从localStorage中获取保存的用户名和密码
    const rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe) {
      this.remember = true;
      this.username = localStorage.getItem("username");
      this.password = localStorage.getItem("password");
    }
  },
  methods: {
    ad() {
      message.info("这是一个消息");
    },
    goToPage(e) {
      window.location.href = e;
    },
    async login() {
      console.log(this.username);
      if (this.remember) {
        // 如果勾选了记住用户名和密码，则保存到localStorage中
        localStorage.setItem("rememberMe", true);
        localStorage.setItem("username", this.username);
        localStorage.setItem("password", this.password);
      } else {
        // 否则清除localStorage中的保存的用户名和密码
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("username");
        localStorage.removeItem("password");
      }
      if (this.username.trim() === "" || this.password.trim() === "") {
        message.info("用户名、密码不能为空！");
        return;
      }
      try {
        console.log("2");
        const res = await loginRequest(this.username, this.password);
        console.log("res", res);
        if (res.data.success) {
          localStorage.setItem(
            "profile",
            JSON.stringify({
              user: this.username,
              access_token: res.data.data.access_token,
            })
          );
          let profile = JSON.parse(localStorage.getItem("profile"));
          console.log(profile.access_token);
          router.push("/main");
        } else {
          message.info("用户名、密码错误！");
        }
      } catch (err) {
        message.info("用户名、密码错误！");
      }
    },
  },
};
</script>
<style scoped>
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh;
}

.t_img {
  width: 70%;
  height: 200px;
  margin-left: auto;
  margin-bottom: 50px;
}

.login_text_1 {
  width: 15%;
  overflow-wrap: break-word;
  color: rgba(52, 52, 52, 1);
  font-size: 200%;
  text-align: center;
  white-space: nowrap;
  line-height: 60px;
  margin-left: 30px;
}

.login_input_1 {
  margin: 50px 0 0 20px;
  background-color: rgba(248, 248, 250, 1);
  border-radius: 12px;
  width: 90%;
}

.icon_1 {
  width: 16px;
  margin: 15px 20px 15px 30px;
}

.login_text_4 {
  overflow-wrap: break-word;
  color: rgb(0, 0, 0);
  font-size: 100%;
  font-family: SourceHanSansSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 38px;
}

.login_input_2 {
  margin: auto;
  background-color: rgba(248, 248, 250, 1);
  border-radius: 12px;
  width: 90%;
  margin: 15px 0 20px 20px;
}

.icon_2 {
  width: 16px;
  margin: 15px 20px 15px 30px;
}

.login_text_5 {
  width: 30%;
  overflow-wrap: break-word;
  color: rgb(0, 0, 0);
  font-size: 100%;
  font-family: SourceHanSansSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 38px;
  margin: auto;
  margin-left: 0px;
}

.login_button_1 {
  height: 50px;
  border-radius: 24px;
  width: 90%;
  margin: 0 0 20px 20px;
  overflow-wrap: break-word;
  font-size: 150%;
  font-family: SourceHanSansSC-Regular;
  font-weight: NaN;
  white-space: nowrap;
  line-height: 36px;
}

.b_img {
  margin: 10% 0 0 0;
  flex: 1;
}

.login-page-logo {
  position: absolute;
  width: 80px;
  top: 150px;
  left: 50px;
}
</style>