import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css';
import { store } from './store.js'
// 1. 引入你需要的组件
import { Button } from "vant";
import { Toast } from "vant";
import { Uploader } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
const app = createApp(App)
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//     app.component(key, component);
// }
app.use(Button);
app.use(Toast);
app.use(Uploader);
app.use(store)
app.use(router)
app.use(Antd)
app.directive('preview', {
    mounted(el, binding) {
        el.addEventListener('click', () => {
            const image = binding.value

            // 创建预览窗口元素
            // 创建预览窗口的DOM元素
            const previewContainer = document.createElement('div');
            previewContainer.classList.add('preview-container');
            previewContainer.style.position = 'fixed'
            previewContainer.style.top = '0'
            previewContainer.style.left = '0'
            previewContainer.style.width = '100%'
            previewContainer.style.height = '100%'
            previewContainer.style.zIndex = '999'

            const previewOverlay = document.createElement('div');
            previewOverlay.classList.add('preview-overlay');
            previewOverlay.style.position = 'absolute'
            previewOverlay.style.top = '0'
            previewOverlay.style.left = '0'
            previewOverlay.style.width = '100%'
            previewOverlay.style.height = '100%'
            previewOverlay.style.backgroundColor = '#00000066'
            // const previewContent = document.createElement('div');
            // previewContent.classList.add('preview-content');
            const previewWindow = document.createElement('div')
            previewWindow.style.position = 'absolute'
            previewWindow.style.top = '50%'
            previewWindow.style.left = '50%'
            previewWindow.style.transform = 'translate(-50%, -50%)'

            // 创建图片元素
            const previewImage = document.createElement('img')
            previewImage.src = image
            previewImage.style.width = '300px'

            // 将图片元素添加到预览窗口中
            previewWindow.appendChild(previewImage)

            // 将预览窗口添加到页面中
            previewContainer.appendChild(previewOverlay);
            previewContainer.appendChild(previewWindow);
            document.body.appendChild(previewContainer)

            // 点击预览窗口时关闭预览窗口
            previewWindow.addEventListener('click', () => {
                document.body.removeChild(previewContainer)
            })
        })
    }
})
app.mount('#app')