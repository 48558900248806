//封装axios做请求处理
import axios from 'axios'
import qs from 'qs'
import router from '@/router'
// import store from '@/store'
import { CONTENT_TYPE, SUCCESS_CODE, TIME_OUT } from '@/utils/constant'

// 返回码值code处理
const codeHandle = (code, message) => {
  //目前不处理
}

// 设置定义baseURL，后续可以配置文件化，线上打包用
const baseURL = '/api'

// axios创建
const service = axios.create({
  baseURL,
  withCredentials: true,
  timeout: TIME_OUT,
  headers: {
    'Content-Type': CONTENT_TYPE.UPLOAD
  }
})

//axios请求拦截器
service.interceptors.request.use(
  config => {
    //获取token处理的地方
    // const tokenVal = store.getters['administrator/tokenVal']
    // if (tokenVal) {
    //   config.headers[TOKEN_KEY] = tokenVal
    // }
    console.log(config)
    if (config.data) {
      // if (config.headers['Content-Type'] === CONTENT_TYPE.FORM) {
      //   config.data = qs.stringify(config.data)
      // }
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

//: axios响应拦截器
service.interceptors.response.use(
  response => {
    const { data, headers, config } = response
    // 如果是文件流的处理方式
    if (headers['content-type'] === CONTENT_TYPE.STREAM) {
      if (!data.code) {
        return {
          blob: data,
          name: decodeURI(headers['content-disposition'].replace('attachment;filename=', ''))
        }
      } else {
        return response.data || null
      }
    }
    // 如果设置的响应类型是 blob 但是返回的不是 文件流的处理方式
    if (config.responseType === 'blob') {
      data.text().then(text => {
        const r = JSON.parse(text)
        const { code, message } = r
        if (!SUCCESS_CODE.includes(code)) {
          codeHandle(code, message)
          return null
        }
        return response.data || null
      })
    } else {
      const { code, message } = data
      //处理非200的请求
      //   if (!SUCCESS_CODE.includes(code)) {
      //     codeHandle(code, message)
      //     return null
      //   }
      return response.data || null
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          console.log('错误请求')
          break
        case 401:
          console.log('未授权，请重新登录')
          break
        case 403:
          console.log('拒绝访问')
          break
        case 404:
          console.log('请求错误,未找到该资源')
          break
        case 405:
          console.log('请求方法未允许')
          break
        case 408:
          console.log('请求超时')
          break
        case 500:
          console.log('服务器端出错')
          break
        case 501:
          console.log('网络未实现')
          break
        case 502:
          console.log('网络错误')
          break
        case 503:
          console.log('服务不可用')
          break
        case 504:
          console.log('网络超时')
          break
        case 505:
          console.log('http版本不支持该请求')
          break
        default:
          console.log(`连接错误${error.response.status}`)
      }
    } else {
      //之后引入弹窗，进行错误提醒
      console.log('未知请求')
    }
    return Promise.reject(error)
  }
)

export default service