import { createStore } from 'vuex'

export const store = createStore({
    state() {
        return {
            record: {}
        }
    },
    mutations: {
        setrecord(state, payload) {
            state.record = payload
        }
    },
    actions: {
        setrecord(context, payload) {
            context.commit('setrecord', payload)
        }
    }
})