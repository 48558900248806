import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login/NewLogin.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/main',
    name: 'main',
    component: () =>
      import('../views/snapphoto.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () =>
      import('../views/qlist.vue')
  },
  {
    path: '/handlesnap',
    name: 'handlesnap',
    component: () =>
      import('../views/handlesnap.vue'),
    props: true
  },
  {
    path: '/handlesnapsee',
    name: 'handlesnapsee',
    component: () =>
      import('../views/handlesnapsee.vue'),
    props: true
  },
  {
    path: '/endhandleq',
    name: 'endhandleq',
    component: () =>
      import('../views/endhandleq.vue'),
    props: true
  },
  {
    path: '/mine',
    name: 'mine',
    component: () =>
      import('../views/mine.vue'),
    props: true
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: () =>
      import('../views/changePassword.vue'),
    props: true
  },
  {
    path: '/safe',
    name: 'safe',
    component: () =>
      import('../views/safe.vue'),
    props: true
  },
  {
    path: '/snapdanger',
    name: 'snapdanger',
    component: () =>
      import('../views/snapdanger.vue'),
    props: true
  },
  {
    path: '/snapquality',
    name: 'snapquality',
    component: () =>
      import('../views/snapquality.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
