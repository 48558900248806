//定义常量的地方
// 请求数据类型
export const CONTENT_TYPE = {
    JSON: 'application/json;charset=UTF-8',
    FORM: 'application/x-www-form-urlencoded;charset=UTF-8',
    UPLOAD: 'multipart/form-data',
    STREAM: 'application/octet-stream;charset=UTF-8'
  }
// 请求超时时长
export const TIME_OUT = 60000 * 2
// 访问秘钥 存储
export const TOKEN_KEY = 'token'
// 秘钥本地存储类型
export const TOKEN_STORAGE = 'sessionStorage'
// 请求成功响应code
export const SUCCESS_CODE = 200